export default {
  data: () => ({
    filterFileMovements: {
      onlyFtpIntegration: false,
      protocolNumber: '',
      createdBy: '',
      financialGroup: '',
      receivingDateStart: '',
      receivingDateEnd: '',
    },
    filterShippingBatches: {
      id: '',
      insuranceCarrierId: [],
      createdAtStart: '',
      createdAtEnd: '',
      sendAtStart: '',
      sendAtEnd: '',
      protocolNumber: '',
      status: '',
      responsable: '',
      batchType: '',
      movementInsuranceCarrierId: '',
      onlyManualProtocol: false,
    },
    filterMovements: {
      protocolNumber: '',
      movementRecordId: '',
      movementInsuranceCarrierId: '',
      fileDataId: '',
      financialGroup: '',
      contract: '',
      subContract: '',
      name: '',
      document: '',
      movementType: [],
      status: [],
      dateType: 'isMovementDate',
      receivingDateStart: '',
      receivingDateEnd: '',
      insuranceCarrierIds: [],
      onlyManualProtocol: false,
      onlyErrors: false,
      appOrigin: 'allChannels',
      pendingMovements: false,
      internalCriticism: 'both',
    },
    filterMyReports: {
      financialGroupId: '',
      reportLayout: '',
      status: '',
      receivingDateStart: '',
      receivingDateEnd: '',
    }
  }),
};
