export default {
  data: () => ({
    filterReportAutomation: {
      financialGroupId: '',
      dataModel: '',
      reportLayout: '',
      reportIntegrationType: '',
      receivingDateStart: '',
      receivingDateEnd: '',
      onlyActive: true
    },
    dataModelTypes: {
      'MOVEMENT_RECORD': 'Movimentação Cadastral',
    },
    reportLayoutTypes: {
      'REPORT_MOVEMENT_RECORD_DEFAULT': 'Relatório de Movimentação Cadastral Padrão',
      'REPORT_MOVEMENT_RECORD_ANALYTICAL': 'Relatório de Movimentação Cadastral Analítico',
    },
    /*
      According to activity Xipp-7957, the IntegrationTypes field
      will initially have a fixed initial value as SFTP.

      Please remove the comment when FTP implementation is carried out.
    */
    integrationTypes: {
      // 'FTP': 'FTP',
      'SFTP': 'SFTP'
    },
    folderHierarchyTypes: {
      'YEAR_MONTH': 'AAAA/MM'
    }
  }),
  methods: {
    getDataModelType(type) {
      return this.dataModelTypes[type] || '-'
    },
    getReportLayoutType(type) {
      return this.reportLayoutTypes[type] || '-'
    },
    getIntegrationType(type) {
      return this.integrationTypes[type] || '-'
    }
  },
  computed: {
    getDataModelTypes() {
      return Object.entries(this.dataModelTypes).map(
        ([id, name]) => ({ id, name }));
    },

    getReportLayoutTypesFilter() {
      return Object.entries(this.reportLayoutTypes).map(
        ([id, name]) => ({ id, name }));
    },

    getIntegrationTypes() {
      return Object.entries(this.integrationTypes).map(
        ([id, name]) => ({ id, name }));
    },
    getFolderHierarchyTypes() {
      return Object.entries(this.folderHierarchyTypes).map(
        ([id, name]) => ({ id, name }));
    }
  }
}
